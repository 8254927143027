@import url('https://fonts.googleapis.com/css?family=Raleway|Roboto+Condensed|Anton');
@import "~datatables.net-bs/css/dataTables.bootstrap.css";
@import "~datatables.net-buttons-bs/css/buttons.bootstrap.css";

//@import "~bootstrap/scss/bootstrap";

@import "~bootstrap-fileinput/css/fileinput.css";
@import "~bootstrap-select/sass/bootstrap-select";

body {
  font-family: 'Calibri', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #444444;
}

h1, h2, h3, h4, h5,
.panel-heading {
  font-family: 'Roboto Condensed', sans-serif;
}

@keyframes field-warning {
  0% {border-color: #ccc;}
  100% {border-color: red;}
}

.required{
  color: red;
}

.required-error{
  animation-name: field-warning;
  animation-duration: 2s;
  animation-iteration-count: 2;
  animation-direction: alternate;
}

form.form-condensed {
  .form-group {
    margin-bottom: 5px;
  }
}

select.form-control{
  padding-left: 7px;
}

p.form-control{
  border-color: #f5f5f5;
  box-shadow: unset;
  background-color: #f5f5f5;
}

div.has-error{
  color: red;
}

.btn-success {
  color: #000;
  background-color: #a3daa3;
  border-color: #4cae4c;
}

.btn-primary {
  color: #000;
  background-color: #aed9ff;
  border-color: #2e6da4;
}

.btn-danger {
  color: #000;
  background-color: #e19996;
  border-color: #d43f3a;
}

div.buttons {
  &.buttons-full {
    .btn {
      width: 100%;
      min-width: 20px;
    }
  }
}

.dt-buttons{
  float: left;
}

.login-form {
  margin-top: 100px;
  .spacer {
    height: 80px;
  }
  #username {
    text-align: center;
  }
}

.panel-heading {
  font-size: 16px;
  padding: 7px 15px;
}

.form-input-in-labels {
  .form-group {
    margin-bottom: 0;
    label {
      width: 100%;
      > span {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: 700;
        line-height: 35px;
      }
    }
  }
}

#messages{
  position: fixed;
  width: 100%;
  z-index: 1005;
  top:10px;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.table td.actions-column{
  text-align: right;
}

div.buttons {
  .btn {
    min-width: 150px;
    &.refresh-button {
      min-width: 40px;
    }
  }
  .full-width {
    min-width: 10px;
    width: 100%;
  }
}

#navbar-top .navbar-brand,
#navbar-top .navbar-nav > li > a {
  color: darkslategray;
}

#navbar-top .navbar-nav > .active > a {
  background-color: darkgreen;
  color: whitesmoke;
}

ul.nav-tabs{
  >li{
    >.header{
      padding: 10px 15px;
      margin-right: 2px;
      line-height: 1.42857143;
      font-weight: bold;
    }
  }
}

ul.navbar-right{
  li{
    margin: auto 8px;
  }
}

.menu-item1{
  padding: 8px 0;
}

.menu-item{
  padding: 15px 0;
}

#payment-selector{
  border: none;
  > li.active > a{
    border: 1px solid darkgray;
    border-radius: 3px;
  }
}

.traumasoft-logo-sm{
  margin-top: -9px;
  max-height: 50px;
}

footer {
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.static-loader {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@import 'common/navbar';
@import 'common/trips';
@import "common/full-page";
@import 'card';
@import "form-validation";
#patients-area {
  @import 'patients/styles';
}
#admin-area {
  @import 'admins/styles';
}
#companies-area{
  @import 'companies/search';
}

@import 'common/invoice';

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}
