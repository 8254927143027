#navbar-top{
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form{
  border: none;
}

.navbar-inverse{
  border: none;
  background-color: transparent;
}

#navbar-top.navbar-inverse .navbar-brand,
#navbar-top.navbar-inverse .navbar-nav > li > a{
  color: black;
}

#logout-form{
  padding-top: 15px;
  padding-bottom: 15px;
}