#trips-paid{
  table{
    .cell6{
      span{
        padding: 3px 15px;
        &.bg-danger{
          background-color: darkred;
          color: whitesmoke;
        }
        &.bg-success{
          background-color: darkgreen;
          color: whitesmoke;
        }
      }
    }
  }
}

#patient-search{
  .panel-body{
    min-height: 212px;
  }
}

#search-patient-form,
#search-patient-by-id-form{

}

.navbar-brand{
  &:before{
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
  }
  padding: 0 15px 0 0;
  img{
    max-width: 215px;
    max-height: 50px;
    display: inline;
  }
  .centered{
    display: inline-block;
    vertical-align: middle;
  }
}

#payments-table{
  .cell10, .cell11, .cell12{
    text-align: center;
    span.captured,
    span.confirmed{
      padding: 3px 5px;
      margin-right: 20px;
    }
  }
}

#payments-details{
  .panel-body{
    padding: 5px 8px;
  }
  .form-control{
    height: 30px;
    padding: 4px 6px;
  }
  .form-group {
    margin-bottom: 6px;
  }
  p{
    margin-bottom: 6px;
  }
}

#change-company-form{
  padding: 8px 0;
}