body.full-height{
  height: 100%;
  .vertical-align {
    display: flex;
    align-items: center;
  }
  #app{
    padding: 0 0 60px;
    height: 100%;
  }
  .container{
    height: 100%;
  }
  .row{
    width: 100%;
  }
  #message{
    position: absolute;
    width: 100%;
  }
}