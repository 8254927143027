#trips-paid{
  table{
    .cell6{
      span{
        padding: 3px 15px;
        &.bg-danger{
          background-color: darkred;
          color: whitesmoke;
        }
        &.bg-success{
          background-color: darkgreen;
          color: whitesmoke;
        }
      }
    }
  }
}

.navbar-brand{
  &:before{
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
  }
  padding: 0 15px 0 0;
  img{
    max-width: 215px;
    max-height: 50px;
    display: inline;
  }
  .centered{
    display: inline-block;
    vertical-align: middle;
  }
}