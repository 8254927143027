#trip-pay{
  .table{
    margin-bottom: 0;
  }
  .cell5{
    width: 65px;
    input{
      width: 55px;
      text-align: right;
    }
  }
}

.trips-row {
  span.glyphicon-ok {
    display: none;
  }
  &.selected {
    background-color: lightgoldenrodyellow;
    //span.glyphicon-ok {
    //  display: inline;
    //}
  }
  &:hover {
    background-color: lightcyan;
  }
  .cell1 {
    width: 55px;
    text-align: center;
  }
  .cell2 {
    width: 100px;
  }
  .cell4 {
    width: 75px;
    text-align: center;
  }
}