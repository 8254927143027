#invoice{
  width: 400px;
  margin-top: 10px;

  h4{
    margin: 10px 0 4px;
    font-family: 'Anton', sans-serif;
  }

  .email-footer{
    margin-top: 20px;
    font-size: 11px;
    img{
      height: 20px;
    }
  }

  .email-header{
    h4{
      margin-top: 25px;
    }
    .patient-name{
      text-transform: capitalize;
    }
  }

  .payment-details{
    margin-top: 10px;
    .header{
      font-weight: bold;
    }
    .cardholder-name{
      text-transform: capitalize;
    }
  }

  .billing-address{
    div{
      margin-left: 5px;
    }
    table{
      width: 100%;
      margin-left: 15px;
    }
  }

  .name-field{
    text-transform: capitalize;
  }
  .invoice-logo{
    text-align: center;
    img{
      max-height: 50px;
      max-width: 270px;
    }
  }
  .invoice-text{
    text-align: center;
    font-family: 'Anton', sans-serif;
    font-size: 33px;
  }

  .items table{
    width: 100%;
    thead{
      border-bottom: 1px solid lightgrey;
      th{
        padding: 0 5px;
      }
    }
    tfoot{
      font-weight: bold;
    }
  }
}