.navbar-brand{
  padding: 10px 15px;
}

.krajee-default.file-preview-frame .kv-file-content {
  width: 230px;
  height: 60px;
}

.krajee-default .file-size-info,
.krajee-default .file-caption-info{
  overflow: visible;
}

#company-view{
  .traumasoft-logo-sm{
    margin-bottom: 10px;
  }
}

#company-edit{
  img.file-preview-image{
    max-height: 50px;
  }
}